// Core Modules
import React from "react";
// Images
const AboutUs = () => {
  return (
    <section id="about" className="about">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>About</h2>
          <p>About Us</p>
        </div>

        <div className="row">
          <div className="col-12">
            <p className="font-italic">
              Pulse is an educational institute exclusively for all science aspirants in the fields of engineering,medicine and much more.We aim to mould a student to seek a career in science.The primary functioning of our institute would be based on individual assessment and constant evaluation.Pulse is about to start it's journey on January 2019,to not pick the best but to make every student a best one.Our unique layout and experienced teaching staffs are all designated to realise the same.
            </p>
            <p className="font-italic">
              Our way of emphasis on all subjects is an improvised yet perfectly scheduled one.A better understanding of the subject is mostly given preference over simple lossy lessons.We train our students to adapt with the varying educational scenarios with this depth in learning.We are always there for them as a solution to face all hard times if at all they can't cope up.
            </p>
            <p className="font-italic">
              Pulse is set up with a motive of awakening all these curiousness of the young generation in science. To extend the border of imagination,To implant breathtaking information,To enhance their will for all competitions,We are Up and We will be running.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
