// Core Modules
import React from "react";
// Images
import Sanid from '../../assets/sanid.jpg';
import Vijesh from '../../assets/vijesh.jpg';
import Anver from '../../assets/anver.jpg';

const Trainers = () => {
  return (
    <section id="trainers" className="trainers" style={{ background: "linear-gradient(135deg, rgba(71,128,157,1) 45%, rgba(156,209,236,1) 100%)" }}>
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2 style={{ color: "white" }}>Directors</h2>
          <p>Our Directors</p>
        </div>

        <div className="row justify-content-center" data-aos="zoom-in" data-aos-delay="100">
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div className="member" style={{ border: "none", boxShadow: "#00000094 2px 2px 6px 0px" }}>
              <img src={Sanid} className="img-fluid" alt="Sanid" />
              <div className="member-content">
                <h4>SANID K</h4>
                <span>B.Tech Mechanical, IIT Indore</span>
                <p>
                  Master of mathematics for JEE Mains and Advanced. Alumni of IIT Indore with teaching experience of over 7 years. Now at Pulse, as a senior faculty he intends to make changes in old traditional learning patterns. His brilliance and experience can help any student to achieve his/her dreams. His experience and success stories are known to all and have attracted students from various locations to better their concepts and understanding.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div className="member" style={{ border: "none", boxShadow: "#00000094 2px 2px 6px 0px" }}>
              <img src={Vijesh} className="img-fluid" alt="Vijesh" />
              <div className="member-content">
                <h4>VIJESH M</h4>
                <span>M.Tech, CUSAT</span>
                <p>
                  He is champion of chemistry for NEET/JEE and board exams. Alumni of CUSAT. He has more than 10 years of teaching experience with various coaching institutes across kerala. His exclusive approach and unmatched teaching styles has made him famous among students. Now at Pulse, he wants to keep students on track with chemistry by helping them with better tools and techniques at their convenience. He wants to put together his own and the students hardwork to bring in a better result.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div className="member" style={{ border: "none", boxShadow: "#00000094 2px 2px 6px 0px" }}>
              <img src={Anver} className="img-fluid" alt="Anver" />
              <div className="member-content">
                <h4>ANVER SADIK K</h4>
                <span>M.Sc, B.Ed., Mathematics</span>
                <p>
                  Mathematics genius for various engineering entrance examinations and board examinations. ANV sir comes with an experience of more than 20 years and his tips for solving mathematics problems are excellent. He has been associated with many premier institutes for teaching. He holds M.Sc. and B.Ed. in Mathematics. His teaching style is ever evolving. He is adaptive to new challenges and new methods. He can motivate students to achieve their goals better than anyone else.
                </p>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
  );
};

export default Trainers;
