const firebaseConfig = {
    apiKey: "AIzaSyDO2NPgMZq-ghD_eGkA3o_YI3FR6IKr66Q",
    authDomain: "pulse-entrance.firebaseapp.com",
    databaseURL: "https://pulse-entrance.firebaseio.com",
    projectId: "pulse-entrance",
    storageBucket: "pulse-entrance.appspot.com",
    messagingSenderId: "459589941235",
    appId: "1:459589941235:web:1553e4cf6f55a8b1fc0486",
    measurementId: "G-X9BJ9YG5CG"
};

export default firebaseConfig;