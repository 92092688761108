// Core Modules
import React from "react";
// Images
import img1 from "../../assets/facilities/img1.jpg"
import img2 from "../../assets/facilities/img2.jpg"
import img3 from "../../assets/facilities/img3.jpg"
import img4 from "../../assets/facilities/img4.jpg"
import img5 from "../../assets/facilities/img5.jpg"
import img6 from "../../assets/facilities/img6.jpg"
import img7 from "../../assets/facilities/img7.jpg"
import img8 from "../../assets/facilities/img8.jpg"
import img9 from "../../assets/facilities/img9.jpg"
import img10 from "../../assets/facilities/img10.jpg"
import img11 from "../../assets/facilities/img11.jpg"
import img12 from "../../assets/facilities/img12.jpg"


const OurFacilities = () => {
  const data = [
    {
      img: img1,
      title: "FACULTY",
      decription: "Board Exam classes and entrance classes are handled by subject experts in Pulse Entrance. All teachers are highly qualified and well experienced. Our faculty panel includes IIT’ians, Doctors, Central University scholars etc."
    },
    {
      img: img2,
      title: "LIBRARY",
      decription: "Well-tuned, good library for “Reading Maketh a Full man….” is set in the institute with latest edition of books for CBSE board examination, NEET, JEE and other entrance examination for a thorough reference of students."
    },
    {
      img: img3,
      title: "COMPUTER LAB",
      decription: "Well-furnished computer lab meticulously designed for computer science students provided with enough appliance for online examination."
    },
    {
      img: img4,
      title: "SCIENCE LAB",
      decription: "A Well set up science lab with enough space, equipments and safety standards benignantly arranged for the practical and thereby stimulating academic elevation in Physics , Chemistry and Biology."
    },
    {
      img: img5,
      title: "SMART ROOM",
      decription: "Well furnished smart class rooms provided with syllabus oriented contents to make the learning easy and enjoyable, thereby enhancing and enriching the academic achievements."
    },
    {
      img: img6,
      title: "MOBILE APP",
      decription: "Academic status quo of each student is very  well communicated through the mobile app. Parents may access the mark lists, appraisal and other documents of scholastic performance of their wards in the app."
    },
    {
      img: img7,
      title: "PLAY GROUND",
      decription: "A satisfactory superb play ground amicably for sports and games ie foot ball, cricket, basket ball, volley ball and shuttle badminton court is also a part of the campus, in fact meeting the standard required for +2 CBSE."
    },
    {
      img: img8,
      title: "HOSTEL FACILITY",
      decription: "Scientifically designed , hygienically well maintained hostel facilities(with enough spacious rooms) separately for girls and boys are set as a part of the institute."
    },
    {
      img: img9,
      title: "TRANSPORTATION",
      decription: "Our school bus is available all the times for the convenience and conveyance of day scholars who come from nearby villages on the payment of reasonable charges. Free R.T.C and concessional Bus passes are also available."
    },
    {
      img: img10,
      title: "MOSQUE",
      decription: "Prayer facilities including for Friday Prayer is also a part of the campus. Seperate prayer halls are provided for boys and girls."
    },
    {
      img: img11,
      title: "MEDICAL FACILITIES",
      decription: "School has a hygienic and modern medical room, equipped with required medicines. Students shall be provided the service of expert doctors whenever required."
    },
    {
      img: img12,
      title: "CANTEEN",
      decription: "Desirably delish homely food sans any preservative is served always with variant covetable menu for each week."
    },
  ]

  return (
    <section id="about" className="about">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <p>Our Facilities</p>
        </div>
        <div className="row">
          {data.map((data, i) => (
            <React.Fragment key={i}>
              <div
                className="col-md-5"
                data-aos="fade-right"
                data-aos-delay="100"
                style={{ marginBottom: "25px" }}
              >
                <img src={data.img} alt={data.title} className="img-fluid" />
              </div>
              <div
                className="col-md-7"
                data-aos="fade-left"
                data-aos-delay="100"
                style={{ marginBottom: "25px" }}
              >
                <h3><strong>{data.title}</strong></h3>
                {data.decription}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurFacilities;
