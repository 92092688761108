// Core Modules
import React, { Component, Fragment } from 'react';
// Custom Components
import Header from '../../components/Header/Header';
import AboutUs from '../../components/AboutUs/AboutUs';
import DirectorsNote from '../../components/DirectorsNote/DirectorsNote';
import Footer from '../../components/Footer/Footer';

class About extends Component {
    
    state = {  }
    
    render() { 
        return ( 
            <Fragment>
                <Header />
                <main id="main">
                    <div style={{marginTop: '60px'}}></div>
                    <AboutUs />
                    <DirectorsNote />
                </main>
                <Footer />
            </Fragment>
        );
    }
}
 
export default About;