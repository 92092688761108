// Core Modules
import React from "react";
// Images

class Online extends React.Component {

  state = {
    username: '',
    password: ''
  };

  textChangeHandler = (event) => {
    let tempId = event.target.id;
    let tempVal = event.target.value;
    switch (tempId) {
      case "etUsername": this.setState({username: tempVal}); break;
      case "etPassword": this.setState({password: tempVal}); break;
      default: break;
    }
  }

  loginUserHandler = () => {
    window.location.href = `https://easyentrance.plus/login/directaccess?username=${this.state.username}&&password=${this.state.password}&&auth=biyuz3305njgrsfmwvqdhxalkpce&&err_url=https://www.pulseentrance.in`;
  }

  render() {
    return (
      <section id="about" className="about">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Online Portal</h2>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-4 col-lg-5 col-md-7 col-sm-9">
              <div
                className="php-email-form"
              >
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="username"
                    id="etUsername"
                    onChange={this.textChangeHandler}
                    value={this.state.username}
                    placeholder="Enter Username"
                  />
                  <div className="validate"></div>
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    id="etPassword"
                    onChange={this.textChangeHandler}
                    value={this.state.password}
                    placeholder="Password"
                  />
                  <div className="validate"></div>
                </div>
                <div className="text-center">
                  <button
                    style={{
                      background: "#009CEA",
                      border: 0,
                      padding: "10px 35px",
                      color: "#fff",
                      transition: "0.4s",
                      borderRadius: "50px",
                      outline: "none",
                    }}
                    onClick={this.loginUserHandler}
                  >
                    Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Online;
