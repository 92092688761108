// Core Modules
import React, { Component } from "react";
// Images
import PulseLogo from "../../assets/pulse.png";

class Header extends Component {
  state = {
    burgerClick: false,
  };

  burgerClickHandler = () => {
    document.body.classList.toggle("mobile-nav-active");
    this.setState({ burgerClick: !this.state.burgerClick });
  };

  render() {
    return (
      <header
        id="header"
        className={
          window.location.pathname !== "/"
            ? "fixed-top header-inner-pages"
            : "fixed-top"
        }
      >
        <button
          type="button"
          className="mobile-nav-toggle d-lg-none"
          onClick={this.burgerClickHandler}
        >
          <i
            className={
              this.state.burgerClick
                ? "icofont-close"
                : "icofont-navigation-menu"
            }
          ></i>
        </button>
        <div
          className="mobile-nav-overly"
          style={{ display: this.state.burgerClick ? "block" : "none" }}
        ></div>

        <nav
          className="mobile-nav"
          style={{ display: this.state.burgerClick ? "block" : "none" }}
        >
          <ul>
            <li className={window.location.pathname === "/" ? "active" : ""}>
              <a href="/">Home</a>
            </li>
            <li
              className={window.location.pathname === "/about" ? "active" : ""}
            >
              <a href="/about">About</a>
            </li>
            <li
              className={
                window.location.pathname === "/publicschool" ? "active" : ""
              }
            >
              <a href="/publicschool">Public School</a>
            </li>
            <li
              className={
                window.location.pathname.includes("/news") ? "active" : ""
              }
            >
              <a href="/news">News & Events</a>
            </li>
            <li
              className={
                window.location.pathname.includes("/links") ? "active" : ""
              }
            >
              <a href="/links">Useful Links</a>
            </li>
            <li
              className={
                window.location.pathname.includes("/gallery") ? "active" : ""
              }
            >
              <a href="/gallery">Gallery</a>
            </li>
            <li
              className={
                window.location.pathname.includes("/contact") ? "active" : ""
              }
            >
              <a href="/contact">Contact</a>
            </li>
          </ul>
        </nav>

        <div className="container d-flex align-items-center">
          <a href="/" className="logo mr-auto">
            <img src={PulseLogo} alt="Pulse" className="img-fluid" />
          </a>

          <nav className="nav-menu d-none d-lg-block">
            <ul>
              <li className={window.location.pathname === "/" ? "active" : ""}>
                <a href="/">Home</a>
              </li>
              <li
                className={
                  window.location.pathname === "/about" ? "active" : ""
                }
              >
                <a href="/about">About</a>
              </li>
              <li
                className={
                  window.location.pathname === "/publicschool" ? "active" : ""
                }
              >
                <a href="/publicschool">Public School</a>
              </li>
              <li
                className={
                  window.location.pathname.includes("/news") ? "active" : ""
                }
              >
                <a href="/news">News & Events</a>
              </li>
              <li
                className={
                  window.location.pathname.includes("/links") ? "active" : ""
                }
              >
                <a href="/links">Useful Links</a>
              </li>
              <li
                className={
                  window.location.pathname.includes("/gallery") ? "active" : ""
                }
              >
                <a href="/gallery">Gallery</a>
              </li>
              <li
                className={
                  window.location.pathname.includes("/contact") ? "active" : ""
                }
              >
                <a href="/contact">Contact</a>
              </li>
            </ul>
          </nav>

          <a href="/online" className="get-started-btn">
            Online Classes
          </a>
        </div>
      </header>
    );
  }
}

export default Header;
