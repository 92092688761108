// Core Modules
import React from "react";
// Images
const WhyUs = () => {
  return (
    <section id="why-us" className="why-us">
      <div className="container" data-aos="fade-up">

        <div className="row">
          <div className="col-lg-4 d-flex align-items-stretch">
            <div className="content">
              <h3>Why Choose Pulse?</h3>
              <p>
                Along with engineering and medical coaching, we also give importance for many other National & International competitive examinations, mock tests and various test series. Also we provide seperate evening entrance batch during working days.
              </p>
              <div className="text-center">
                <a href="/about" className="more-btn">Learn More <i className="bx bx-chevron-right"></i></a>
              </div>
            </div>
          </div>
          <div className="col-lg-8 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
            <div className="icon-boxes d-flex flex-column justify-content-center">
              <div className="row">
                <div className="col-xl-6 d-flex align-items-stretch">
                  <div className="icon-box mt-4 mt-xl-0" style={{ backgroundColor: "#009CEA", color: "white", borderRadius: "4px" }}>
                    <i className="bx bx-receipt"></i>
                    <h4>Mission</h4>
                    <p style={{ color: "white" }}>To deliver quality education to each and every student to ensure his or her success. Our education system is developed in such a way that it works towards the overall develoment of it's students by strengthening the brightest and young minds to achieve best in their lives.</p>
                  </div>
                </div>
                <div className="col-xl-6 d-flex align-items-stretch">
                  <div className="icon-box mt-4 mt-xl-0" style={{ backgroundColor: "#009CEA", color: "white", borderRadius: "4px" }}>
                    <i className="bx bx-cube-alt"></i>
                    <h4>Vision</h4>
                    <p style={{ color: "white" }}>To be the most trusted brand in entrance coaching sector by helping the young minds to achieve their dreams through quality education. Also build technology enabled systems to provide the best of us to our students.</p>
                  </div>
                </div>
                {/* <div className="col-xl-4 d-flex align-items-stretch">
                  <div className="icon-box mt-4 mt-xl-0">
                    <i className="bx bx-images"></i>
                    <h4>Labore consequatur</h4>
                    <p>Aut suscipit aut cum nemo deleniti aut omnis. Doloribus ut maiores omnis facere</p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  );
};

export default WhyUs;
