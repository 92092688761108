// Core Modules
import React from "react";
// Depandancy Modules
import * as firebase from "firebase/app";
import 'firebase/database';

class Gallery extends React.Component {
  state = {
    data: false,
    odd: false
  };

  componentDidMount() {
    // fetch images
    this.fetchData();
  }

  fetchData() {
    this.props.setLoading(true);
    firebase.database().ref(`/gallery/`).on('value', (snapshot) => {
      let data = snapshot.val();
      this.props.setLoading(false);
      if (data) {
        this.setState({ data: Object.values(data) }, () => {
          if (this.state.data.length % 2 !== 0) this.setState({ odd: true });
        });
      } else {
        this.setState({ data: [] });
      }
    });
  }

  fadeDirection(i) {
    if (this.state.odd) {
      if (i === this.state.data.length - 1) return "fade-up";
    }
    if (i % 2 === 0) return "fade-right";
    else return "fade-left";
  }

  render() {
    return (
      <section id="about" className="about">
        {this.state.data ? (
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Gallery</h2>
            </div>
            <div className="row justify-content-center">
              {this.state.data.length === 0 ? (
                <div className="font-italic">No new updates</div>
              ) : (
                  this.state.data.map((data, i) => (
                    <div
                      className="col-md-6"
                      data-aos={this.fadeDirection(i)}
                      data-aos-delay="100"
                      style={{ marginBottom: "25px" }}
                      key={i}
                    >
                      <img src={data.imageUrl} alt={data.Filename} className="img-fluid" />
                    </div>
                  )))}
            </div>
          </div>
        ) : ""}
      </section>
    );
  }
}

export default Gallery;
