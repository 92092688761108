// Core Modules
import React from "react";
// Images
const Facilities = () => {
  return (
    <section id="features" className="features" style={{ background: "linear-gradient(135deg, rgba(71,128,157,1) 45%, rgba(156,209,236,1) 100%)", color: "white", padding: "60px 0" }}>
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2 style={{ color: "white" }}>FACILITIES</h2>
          <p>FACILITIES</p>
        </div>
        <div className="row" data-aos="zoom-in" data-aos-delay="100">
          <div className="col-lg-3 col-md-4 col-6 mt-4 mt-md-0">
            <div className="icon-box">
              <i className="fas fa-snowflake" style={{ color: "#ffbb2c" }}></i>
              <h3>A/C Classrooms</h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6 mt-4 mt-md-0">
            <div className="icon-box">
              <i className="fas fa-video" style={{ color: "#5578ff" }}></i>
              <h3>CCTV Surveillance</h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6 mt-4 mt-md-0">
            <div className="icon-box">
              <i className="fas fa-fingerprint" style={{ color: "#e80368" }}></i>
              <h3>Biometric Attendance</h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6 mt-4 mt-lg-0">
            <div className="icon-box">
              <i className="fas fa-book" style={{ color: "#e361ff" }}></i>
              <h3>Study Materials</h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6 mt-4">
            <div className="icon-box">
              <i className="fas fa-book-reader" style={{ color: "#47aeff" }}></i>
              <h3>Library</h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6 mt-4">
            <div className="icon-box">
              <i className="fab fa-internet-explorer" style={{ color: "#ffa76e" }}></i>
              <h3>Online Support</h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6 mt-4">
            <div className="icon-box">
              <i className="fab fa-google-play" style={{ color: "#11dbcf" }}></i>
              <h3>Mobile App</h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6 mt-4">
            <div className="icon-box">
              <i className="fas fa-comments" style={{ color: "#4233ff" }}></i>
              <h3>SMS Alert</h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6 mt-4">
            <div className="icon-box">
              <i className="fas fa-pray" style={{ color: "#b2904f" }}></i>
              <h3>Prayer Hall</h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6 mt-4">
            <div className="icon-box">
              <i className="fas fa-chalkboard-teacher" style={{ color: "#b20969" }}></i>
              <h3>Well Experienced Faculty</h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6 mt-4">
            <div className="icon-box">
              <i className="fas fa-graduation-cap" style={{ color: "#ff5828" }}></i>
              <h3>Scholarship</h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6 mt-4">
            <div className="icon-box">
              <i className="fas fa-stethoscope" style={{ color: "#29cc61" }}></i>
              <h3>Pulse Doubt Clinic</h3>
            </div>
          </div>
        </div>

      </div>
    </section>
  );
};

export default Facilities;
