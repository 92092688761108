// Core Modules
import React, { Component, Fragment } from "react";
// Custom Components
import Header from "../../components/Header/Header";
import Features from "../../components/Features/Features";
import OurFacilities from "../../components/OurFacilities/OurFacilities";
import Footer from "../../components/Footer/Footer";

class PublicSchool extends Component {
  state = {};

  render() {
    return (
      <Fragment>
        <Header />
        <main id="main">
          <div style={{ marginTop: "60px" }}></div>
          <Features />
          <OurFacilities />
        </main>
        <Footer />
      </Fragment>
    );
  }
}

export default PublicSchool;
