// Core Modules
import React from "react";
// Depandancy Modules
import * as firebase from "firebase/app";
import 'firebase/database';

class IndividualNews extends React.Component {
  state = {
    newsId: this.props.newsId,
    data: false,
  };

  componentDidMount() {
    //fetch news
    this.fetchData();
  }

  fetchData() {
    this.props.setLoading(true);
    firebase.database().ref(`/news/${this.state.newsId}`).on('value', (snapshot) => {
      let data = snapshot.val();
      this.props.setLoading(false);
      this.setState({ data: data })
    });
  }

  render() {
    return (
      <section id="about" className="about">
        {this.state.data ? (
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <p>{this.state.data.title}</p>
            </div>
            <div className="row">
              <div className="col-12">
                <h2 data-aos="fade-right">
                  <sup><i className="fa fa-quote-left" aria-hidden="true"></i></sup>
                  {this.state.data.subtitle}
                  <sup><i className="fa fa-quote-right" aria-hidden="true"></i></sup>
                </h2>
                <br />
                <div className="font-italic" data-aos="fade-left">{this.state.data.description}</div>
              </div>
            </div>
          </div>
        ) : ""}
      </section>
    );
  }
}

export default IndividualNews;
