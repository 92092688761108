// Core Modules
import React, { Fragment } from "react";
// Depandancy Modules
import * as firebase from "firebase/app";
import 'firebase/database';

class Englinks extends React.Component {
  state = {
    data: false,
    title: this.props.title,
    path: this.props.path
  };

  componentDidMount() {
    //fetch links
    this.fetchData();
  }

  fetchData() {
    if (this.props.setLoading)
      this.props.setLoading(true);
    firebase.database().ref(`/links/${this.state.path}/`).on('value', (snapshot) => {
      let data = snapshot.val();
      if (this.props.setLoading)
        this.props.setLoading(false);
      if (data) {
        this.setState({ data: Object.values(data) });
      } else {
        this.setState({ data: [] });
      }
    });
  }

  render() {
    return (
      <Fragment>
        {this.state.data ? (
          <div data-aos="fade-up">
            <div data-aos="fade-in">
              <div className="section-title">
                <p>{this.state.title}</p>
              </div>
            </div>
            {this.state.data.length === 0 ? (
              <div className="font-italic">No new updates</div>
            ) : (
                this.state.data.map((data, i) => (
                  <div className="row" style={{ marginBottom: "10px" }} key={i}>
                    <div
                      className="col-lg-5 col-md-6"
                      style={{ marginBottom: "10px" }}
                      data-aos="fade-right"
                    >
                      <img
                        src={data.imageUrl}
                        alt={data.fileName}
                        className="img-fluid"
                      ></img>
                    </div>
                    <div
                      className="col-lg-7 col-md-6"
                      style={{ marginBottom: "10px" }}
                      data-aos="fade-left"
                    >
                      <h3>
                        <strong>{data.title}</strong>
                      </h3>
                      {data.description}
                      <br />
                      <a href={`https://${data.url}`} target="_blank" rel="noopener noreferrer">
                        View website
                    </a>
                    </div>
                  </div>
                )))}
          </div>
        ) : ""}
      </Fragment>
    );
  }
}

export default Englinks;
