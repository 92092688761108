// Core Modules
import React, { Component, Fragment } from "react";
// Custom Components
import Header from "../../components/Header/Header";
import News from "../../components/IndividualNews/IndividualNews";
import Footer from "../../components/Footer/Footer";
import Loading from "../../components/Loading/Loading";

class IndividualNews extends Component {
  state = {
    isLoading: false
  };

  setLoading = (val) => {
    this.setState({ isLoading: val })
  }

  render() {
    return (
      <Fragment>
        {this.state.isLoading ? <Loading /> : ""}
        <Header />
        <main id="main">
          <div style={{ marginTop: "60px" }}></div>
          <News newsId={this.props.match.params.id} setLoading={this.setLoading} />
        </main>
        <Footer />
      </Fragment>
    );
  }
}

export default IndividualNews;
