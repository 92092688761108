// Core Modules
import React from "react";
// Css
import "./Loading.css";
// Image
import logo from "../../assets/logo.png"

const Loading = () => {
    return (
        <div id="loader">
            <div id="loader-body">
                <div className="logo-div" >
                    <img src={logo} alt="logo" />
                    <div></div>
                </div>
            </div>
        </div>
    );
}

export default Loading;