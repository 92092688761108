import React from 'react';
// Styles
import './Hero.css';

const Hero = () => {
	return (
		<section
			id='hero'
			className='d-flex justify-content-center align-items-center'
		>
			<div
				className='container position-relative'
				data-aos='zoom-in'
				data-aos-delay='100'
			>
				<h1>
					Learning Today,
					<br />
					Leading Tomorrow
				</h1>
				<h2>
					We are team of talanted faculties providing world class education
				</h2>
				<a
					href='https://docs.google.com/forms/d/e/1FAIpQLSfUw41CyFDgtm6KuAf9DGfI5l0Sor3BLHWT8CTtUJwi37cYUQ/viewform'
					target='_blank'
					rel='noopener noreferrer'
					className='btn-get-started'
				>
					Join Now
				</a>
			</div>
		</section>
	);
};

export default Hero;
