// Core Modules
import React, { Component, Fragment } from "react";
// Custom Components
import Header from "../../components/Header/Header";
import Links from "../../components/Links/Links";
import Footer from "../../components/Footer/Footer";
import Loading from "../../components/Loading/Loading";

class UsefulLinks extends Component {
  state = {
    isLoading: false
  };

  setLoading = (val) => {
    this.setState({ isLoading: val })
  }

  render() {
    return (
      <Fragment>
        {this.state.isLoading ? <Loading /> : ""}
        <Header />
        <main id="main">
          <div style={{ marginTop: "60px" }}></div>
          <section id="about" className="about">
            <div className="container">
              {this.state.isLoading ? "" : (
                <div className="section-title" data-aos="fade-up">
                  <h2>Useful Links</h2>
                </div>)}
              <Links title="Engineering" path="engg" setLoading={this.setLoading} />
              <hr style={{ margin: "2rem 0" }} />
              <Links title="Medicine" path="med" />
            </div>
          </section>
        </main>
        <Footer />
      </Fragment>
    );
  }
}

export default UsefulLinks;
