// Core Modules
import React from "react";
// Images
import AboutImage from "../../assets/about.jpg";
// Depandancy Modules
import * as firebase from "firebase/app";
import 'firebase/database';

const About = (props) => {

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    // fetch data
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = () => {
    props.setLoading(true);
    firebase.database().ref('/sliderimgs/').on('value', (snapshot) => {
      let data = snapshot.val();
      props.setLoading(false);
      if (data) {
        setData(Object.values(data));
      }
    });
  }

  return (
    <React.Fragment>
      {data.length !== 0 ?
        (
          <div className="container" style={{ marginTop: "60px", marginBottom: "60px" }} data-aos="zoom-in-up">
            <div className="row justify-content-center">
              <div className="col-12">
                <div
                  id="carouselIndicators"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <ol className="carousel-indicators">
                    {data.map((data, i) =>
                      i === 0 ? (
                        <li
                          data-target="#carouselIndicators"
                          data-slide-to={i}
                          className="active"
                          key={i}
                        ></li>
                      ) : (
                          <li
                            data-target="#carouselIndicators"
                            data-slide-to={i}
                            key={i}
                          ></li>
                        )
                    )}
                  </ol>
                  <div className="carousel-inner">
                    {data.map((data, i) =>
                      i === 0 ? (
                        <div className="carousel-item active" key={i}>
                          <img
                            className="d-block w-100"
                            src={data.imageUrl}
                            alt={data.Filename}
                          />
                        </div>
                      ) : (
                          <div className="carousel-item" key={i}>
                            <img
                              className="d-block w-100"
                              src={data.imageUrl}
                              alt={data.Filename}
                            />
                          </div>
                        )
                    )}
                  </div>
                  <a
                    className="carousel-control-prev"
                    href="#carouselIndicators"
                    role="button"
                    data-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="carousel-control-next"
                    href="#carouselIndicators"
                    role="button"
                    data-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Next</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : ""
      }

      <section id="about" className="about" style={{ background: "linear-gradient(135deg, rgba(71,128,157,1) 45%, rgba(156,209,236,1) 100%)", color: "white" }}>
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2 style={{ color: "white" }}>About</h2>
            <p>About Us</p>
          </div>

          <div className="row">
            <div
              className="col-lg-6 order-1 order-lg-2"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <img src={AboutImage} className="img-fluid" alt="about" style={{ boxShadow: "#00000094 2px 2px 6px 0px" }} />
            </div>

            <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
              <p className="font-italic" style={{ lineHeight: "2rem" }}>
                Pulse is an exclusive institute for engineering/medical entrance
                coaching. It is one of the top institutes of kerala for JEE MAIN &
                ADVANCED, NEET, AIIMS, NTSE, KVPY, etc. It is a dream project by
                some of the top most experts from all over Kerala. Our intention
                is to become a leading education service provider company and to
                accomplish this, we are contributing to our country through
                excelence in Pre-engineering & Pre-medical examinations. The
                foundation of PULSE was laid with the establishment of Pulse
                institute at Kondotty in January 2019
            </p>
              <a href="/about" className="learn-more-btn">
                Learn More
            </a>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default About;
