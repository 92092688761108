// Core Modules
import React from "react";
// Images
const Courses = () => {
  return (
    <section id="popular-courses" className="courses">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Courses</h2>
          <p>Popular Courses</p>
        </div>

        <div className="row" data-aos="zoom-in" data-aos-delay="100">

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" style={{marginBottom: '20px'}}>
            <div className="course-item" style={{width: '100%'}}>
              <div className="course-content">
                <h3>PLUS ONE SCIENCE</h3>
                <p>Entance Oriented Tuition.<br/> Duration: 1 Year</p>
              </div>
            </div>
          </div> 

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" style={{marginBottom: '20px'}}>
            <div className="course-item" style={{width: '100%'}}>
              <div className="course-content">
                <h3>PLUS TWO SCIENCE</h3>
                <p>Entance Oriented Tuition.<br/> Duration: 1 Year</p>
              </div>
            </div>
          </div> 

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" style={{marginBottom: '20px'}}>
            <div className="course-item" style={{width: '100%'}}>
              <div className="course-content">
                <h3>MED/ENGG. ENTRANCE CRASH COURSE</h3>
                <p>Commence Just after Plus Two Board Exam.<br/> Duration: 2 Months</p>
              </div>
            </div>
          </div> 
          
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" style={{marginBottom: '20px'}}>
            <div className="course-item" style={{width: '100%'}}>
              <div className="course-content">
                <h3>PULSE PUBLIC SCHOOL (CBSE)</h3>
                <p>
                  Plus One, Plus Two Regular Schooling with MED/ENGG Entrance Coaching.
                  <br/> 
                  Duration: 2 Year
                  <br/>
                  <strong>Hostel Facility Available for Boys and Girls</strong>  
                </p>
              </div>
            </div>
          </div> 
          
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" style={{marginBottom: '20px'}}>
            <div className="course-item" style={{width: '100%'}}>
              <div className="course-content">
                <h3>FOUNDATION PROGRAMMES</h3>
                <p>For Class 8th, 9th and 10th CBSE Students</p>
              </div>
            </div>
          </div> 

        </div>

      </div>
    </section>
  );
};

export default Courses;
