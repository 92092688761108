// Core Modules
import React from "react";
// Images

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 footer-contact">
              <h3>Pulse</h3>
              <p>
                Ayakkoden Tower, <br />
				        Near Ayyappa Temple,
                <br />
                Bypass road, <br />
				        Kondotty, 673 638
                <br />
                <br />
                <strong>Phone:</strong> +91 9061 765 333, <br /> +91 9961 765 333
                <br />
                <strong>Email:</strong> pulseentrance@gmail.com
                <br />
              </p>
            </div>

            <div className="col-lg-2 col-md-6 footer-links"></div>

            <div className="col-lg-3 col-md-6 footer-links"></div>

            <div className="col-lg-4 col-md-6 footer-newsletter">
              {/* <h4>Join Our Newsletter</h4>
              <p>
                Join our newsletter to get updates by mail.
              </p>
              <form action="" method="post">
                <input type="email" name="email" />
                <input type="submit" value="Subscribe" />
              </form> */}
            </div>
          </div>
        </div>
      </div>

      <div className="container d-md-flex py-4">
        <div className="mr-md-auto text-center text-md-left">
          <div className="copyright">
            &copy; Copyright{" "}
            <strong>
              <span>Pulse</span>
            </strong>
            . All Rights Reserved
          </div>
          <div className="credits"></div>
        </div>
        <div className="social-links text-center text-md-right pt-3 pt-md-0">
          <a href="https://www.facebook.com/pulseentrance/" rel="noopener noreferrer" target="_blank" className="facebook">
            <i className="bx bxl-facebook"></i>
          </a>
          <a href="https://www.instagram.com/pulseentrance/" rel="noopener noreferrer" target="_blank" className="instagram">
            <i className="bx bxl-instagram"></i>
          </a>
          <a href="https://play.google.com/store/apps/details?id=in.pulseentrance.pulseentrance" target="_blank" rel="noopener noreferrer" className="google-plus">
            <i className='bx bxl-play-store'></i>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
