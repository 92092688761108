// Core Modules
import React, { Component, Fragment } from "react";
// Custom Components
import Header from "../../components/Header/Header";
import OnlineComponent from "../../components/Online/Online";
import Footer from "../../components/Footer/Footer";

class Online extends Component {
  state = {};

  render() {
    return (
      <Fragment>
        <Header />
        <main id="main">
          <div style={{ marginTop: "60px" }}></div>
          <OnlineComponent />
        </main>
        <Footer />
      </Fragment>
    );
  }
}

export default Online;
