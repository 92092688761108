// Core Modules
import React from "react";
// Depandancy Modules
import * as firebase from "firebase/app";
import 'firebase/database';

class News extends React.Component {
  state = {
    data: false,
  };

  componentDidMount() {
    //fetch news
    this.fetchData();
  }

  fetchData() {
    this.props.setLoading(true);
    firebase.database().ref('/news/').on('value', (snapshot) => {
      let data = snapshot.val();
      this.props.setLoading(false);
      if (data) {
        let dataList = Object.values(data);
        let keysList = Object.keys(data);
        for (let i = 0; i < dataList.length; i++)
          dataList[i].key = keysList[i];
        this.setState({ data: dataList });
      } else {
        this.setState({ data: [] });
      }
    });
  }

  render() {
    return (
      <section id="about" className="about">
        {this.state.data ? (
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>News {"&"} Events</h2>
            </div>
            <div className="row">
              <div className="col-12">
                {this.state.data.length === 0 ? (
                  <div className="font-italic">No new updates</div>
                ) : (
                    this.state.data.map((news, i) => (
                      <div
                        className="font-italic"
                        key={i}
                        data-aos={i % 2 === 0 ? "fade-right" : "fade-left"}
                      >
                        <strong>{news.title}</strong>
                        <br />
                        {news.subtitle}
                        <div style={{ textAlign: "right" }}>
                          <a href={`news/${news.key}`}>View more</a>
                        </div>
                        {i !== this.state.data.length - 1 ? <hr /> : ""}
                      </div>
                    )))}
              </div>
            </div>
          </div>
        ) : ""}
      </section>
    );
  }
}

export default News;
