// Core Modules
import React, { Component, Fragment } from "react";
// Custom Components
import Header from "../../components/Header/Header";
import ContactComponent from "../../components/Contact/Contact";
import Footer from "../../components/Footer/Footer";

class Contact extends Component {
  state = {};

  render() {
    return (
      <Fragment>
        <Header />
        <main id="main">
          <div style={{ marginTop: "60px" }}></div>
          <ContactComponent />
        </main>
        <Footer />
      </Fragment>
    );
  }
}

export default Contact;
