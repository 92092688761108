// Core Modules
import React from "react";

const Features = () => {
  return (
    <section id="about" className="about">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Pulse public school</h2>
          <p>Features</p>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="font-italic">
              <ul>
                <li>Classes handled by entrance experts</li>
                <li>Comprehensive study materials for entrance and board exams.</li>
                <li>Daily Practice Tests (DPT, Both objective and subjective)</li>
                <li>Unit tests. After completion of each unit and chapter</li>
                <li>Entrance classes for each chapter and model examinations.</li>
                <li>Individual doubt clearance sessions for students at hostel</li>
                <li>Online examination platform</li>
                <li>Stress free methodologies.</li>
                <li>Vibrant PTA</li>
                <li>Personality development and counselling classes</li>
                <li>Strict and disciplined campus</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
