// Core Modules
import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// Custom Containers
import Home from "./containers/Home/Home";
import About from "./containers/About/About";
import PublicSchool from "./containers/PublicSchool/PublicSchool";
import News from "./containers/News/News";
import IndividualNews from "./containers/IndividualNews/IndividualNews";
import UsefulLinks from "./containers/UsefulLinks/UsefulLinks";
import Gallery from "./containers/Gallery/Gallery";
import Contact from "./containers/Contact/Contact";
import Online from "./containers/Online/Online";
// Depandancy Modules
import * as firebase from "firebase/app";
// Depandancy Files
import fireConfig from './config/firebase.config';
// Initialize Firebase project
firebase.initializeApp(fireConfig);

class App extends Component {
  state = {};

  render() {
    return (
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/about" exact component={About} />
        <Route path="/publicschool" exact component={PublicSchool} />
        <Route path="/news" exact component={News} />
        <Route path="/news/:id" exact component={IndividualNews} />
        <Route path="/links" exact component={UsefulLinks} />
        <Route path="/gallery" exact component={Gallery} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/online" exact component={Online} />
        <Redirect to="/" />
      </Switch>
    );
  }
}

export default App;
