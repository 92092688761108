// Core Modules
import React, { Component, Fragment } from 'react';
// Custom Components
import Header from '../../components/Header/Header';
import Hero from '../../components/Hero/Hero';
import About from '../../components/About/About';
import WhyUs from '../../components/WhyUs/WhyUs';
import Facilities from '../../components/Facilities/Facilities';
import Courses from '../../components/Courses/Courses';
import Trainers from '../../components/Trainers/Trainers';
import Footer from '../../components/Footer/Footer';
import Loading from "../../components/Loading/Loading";

class Home extends Component {

    state = {
        isLoading: false
    };

    setLoading = (val) => {
        this.setState({ isLoading: val })
    }

    render() {
        return (
            <Fragment>
                {this.state.isLoading ? <Loading /> : ""}
                <Header />
                <Hero />
                <main id="main">
                    <About setLoading={this.setLoading} />
                    <WhyUs />
                    <Facilities />
                    <Courses />
                    <Trainers />
                </main>
                <Footer />
            </Fragment>
        );
    }
}

export default Home;