// Core Modules
import React from "react";
// Images
const DirectorsNote = () => {
  return (
    <section id="about" className="about">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          {/* <h2>About</h2> */}
          <p>from director's desk</p>
        </div>

        <div className="row">
          <div className="col-12">
            <p className="font-italic">
              Dear Students,
              <br />
              <br />
              Welcome to Pulse,
            </p>
            <p className="font-italic">
              We would like to appreciate you for the belief you have shown in
              Pulse. We believe that inorder to excel and to improve the quality
              of education on constant basis, it is required to seek and adopt
              innovative methods. Hence we at PULSE, are constantly putting our
              efforts to transfer the knowledge of our esteemed faculty member
              to the young talents of our institute. Our aim is to provide the
              best of us to you by being a pleasant experience. Our success lies
              within you. Wishing you all the best.
            </p>
            <p className="font-italic">
              Sincerely,
              <br />
              Sanid K,
              <br />
              Vijesh M,
              <br />
              Anver Sadik K
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DirectorsNote;
